import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import { Container, Row, Col } from "react-bootstrap"

export default () => (
	<Layout style={{ color: `teal` }}>
		<Helmet title="Thank you for contacting us | RWK Attorneys" defer={false} />

		<Container className="my-5">
			<Row>
                <Col md="6">
                    <img className="img-fluid" src="/bruce-mars-AndE50aaHn4-unsplash.jpg" />
                </Col>
				<Col>
					<h1>You did it!</h1>
                    <p className="lead">We will be in contact with you soon to schedule your free consultation.</p>
                    <p>Please call us for urgent matters.</p>
				</Col>
			</Row>
		</Container>
	</Layout>
)